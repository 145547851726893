var winScrollTop = 0;
var winHeight = $(window).height();
var winWidth = $(window).width();

var resizeCachedParallaxItemsQueued = false;
var updateCachedParallaxItemsQueued = false;
var transformCachedParallaxItemsQueued = false
var updateParallaxDisplayQueued = false;
var updateNavScrollStyleQueued = false;

var parallaxItems = [];

/* cache jQuery Selectors */
var $background = $(".background");

// Bind Scroll event handler
$(window).scroll(function () {
	onScroll();
});

// Bind resize event handler
$(window).resize(function () {
	onResize();
});

// Bind load event handler
$(window).load(function () {
	onLoad()
});

// Bind focus event handler
$(window).focus(function () {
	onFocus()
});

function onLoad() {
	createParallaxItemCache();
	resizeCachedParallaxItems();
	initScrollSpy();
	initScrollToNav();
	initFlexSlider();
	initTeamOverlays();
	loadGoogleMapsScript();
}

function onResize() {
	winHeight = $(window).height();
	winWidth = $(window).width();

	if (!updateCachedParallaxItemsQueued) {
		updateCachedParallaxItemsQueued = true;
		requestAnimationFrame(updateCachedParallaxItems);
	}

	if (!resizeCachedParallaxItemsQueued) {
		resizeCachedParallaxItemsQueued = true;
		requestAnimationFrame(resizeCachedParallaxItems);
	}

	if (!transformCachedParallaxItemsQueued) {
		transformCachedParallaxItemsQueued = true;
		requestAnimationFrame(transformCachedParallaxItems);
	}
}

function onScroll() {
	winScrollTop = $(window).scrollTop();

	if (!transformCachedParallaxItemsQueued) {
		transformCachedParallaxItemsQueued = true;
		requestAnimationFrame(transformCachedParallaxItems);
	}

	if (!updateNavScrollStyleQueued) {
		updateNavScrollStyleQueued = true;
		requestAnimationFrame(updateNavScrollStyle);
	}
}

function onFocus() { }

function initScrollSpy() {
	//Scroll Spy navigation and animated service boxes on initial view
	$('[data-spy="scroll"]').each(function () {
		var $spy = $(this).scrollspy('refresh');
	});

	window.hasAnimatedServiceBoxes = false;

	$('[data-spy="scroll"]').on('activate.bs.scrollspy', function ($this) {
		if ($(this).find("li.active a").attr("href") === "#approach" && window.hasAnimatedServiceBoxes === false) {
			//TODO -  wrap this in each to allow for dynamic column numbers and container assignments
			animateClass($('#service-box-1'), 900);
			setTimeout(function () { animateClass($('#service-box-2'), 900); }, 300);
			setTimeout(function () { animateClass($('#service-box-3'), 900); }, 600);

			window.hasAnimatedServiceBoxes = true;
		}
	});
}

function initScrollToNav() {
	$("#nav-primary ul li a[href^='#']").not('.home-nav').on('click', function (e) {
		e.preventDefault();

		var hash = this.hash;
		var offset = ($(hash).offset().top - 84);

		//var wrapper = $('#wrapper');
		//wrapper.css('transition', '0.3s');

		//wrapper.css('transform', 'translate3d(0, ' + offset + 'px, 0)');

		$('html, body').animate({
			scrollTop: offset
		}, 300, function () {
			// when done, add hash to url
			// (default click behaviour)
			//window.location.hash = hash;
		});
	});
}

function updateNavScrollStyle() {
	updateNavScrollStyleQueued = false;

	var winScroll = $(window).scrollTop();
	var homeHeight = $('#home').outerHeight() - $('#nav-primary').height() - 2;

	if (winScroll > homeHeight) {
		$('nav.transparent').addClass('scroll');
	} else {
		$('nav.transparent').removeClass('scroll');
	}
}

function initFlexSlider() {
	//Flexslider defaults
	$('.flexslider').flexslider({
		animation: "slide",
		direction: "horizontal",
		slideshow: true,
		slideshowSpeed: 12000,
		animationDuration: 500,
		directionNav: true,
		controlNav: false,
		start: function () { onResize(); },
	});
}

function initTeamOverlays() {
	//Team photo overlay hovers
	//TODO - Replace with CSS3 transitions
	//img overlays
	$('.team-thumb').on('mouseover', function () {
		var overlay = $(this).find('.team-overlay');
		var content = $(this).find('.overlay-content');

		overlay.stop(true, true).fadeIn(600);
		content.stop().animate({
			'top': "40%",
			opacity: 1
		}, 600);
	}).on('mouseleave', function () {
		var overlay = $(this).find('.team-overlay');
		var content = $(this).find('.overlay-content');

		content.stop().animate({ 'top': "60%", opacity: 0 }, 300, function () {
			content.css('top', "20%");
		});

		overlay.fadeOut(300);
	});
}

function createParallaxItemCache() {
	$(".parallax").each(function (i) {
		var element = $(this);

		var parallaxItem = buildParallaxItem(element);

		// Add to cache
		parallaxItems.push(parallaxItem);
	});
}

function updateCachedParallaxItem(parallaxItem) {
	var i = parallaxItems.indexOf(parallaxItem);
	parallaxItems[i] = buildParallaxItem(parallaxItems[i].element);
}

function updateCachedParallaxItems() {
	//console.log("updateCachedParallaxItems()");

	updateCachedParallaxItemsQueued = false;

	$.each(parallaxItems, function (index, parallaxItem) {
		updateCachedParallaxItem(parallaxItem);
	});
}

function buildParallaxItem(element) {
	var parallaxItem = {};

	parallaxItem.container = element.parent();
	parallaxItem.containerHeight = element.parent().height();
	parallaxItem.containerWidth = element.parent().width();
	parallaxItem.element = element;
	parallaxItem.originalBackgroundHeight = element.attr("data-img-height");
	parallaxItem.originalBackgroundWidth = element.attr("data-img-width");
	parallaxItem.travel = parseInt(element.attr("data-diff"));
	parallaxItem.travel = parallaxItem.travel ? parallaxItem.travel : 0;

	parallaxItem.parallaxRatio = parallaxItem.travel / (winHeight + parallaxItem.containerHeight);
	parallaxItem.bgContainerHeight = parallaxItem.containerHeight + parallaxItem.travel - (parallaxItem.containerHeight * (parallaxItem.parallaxRatio * 2));

	//Handle the image sizing and width constraints

	var imageAspectRatio = parallaxItem.originalBackgroundWidth / parallaxItem.originalBackgroundHeight;
	var containerAspectRatio = parallaxItem.containerWidth / (parallaxItem.containerHeight + parallaxItem.travel);

	if (imageAspectRatio < containerAspectRatio) { // image is height constrained
		parallaxItem.constrainedBackgroundWidth = parallaxItem.containerWidth;
		parallaxItem.constrainedBackgroundHeight = Math.round(parallaxItem.originalBackgroundHeight * (parallaxItem.constrainedBackgroundWidth / parallaxItem.originalBackgroundWidth));
	}
	else { // image is width constrained
		parallaxItem.constrainedBackgroundHeight = parallaxItem.containerHeight + parallaxItem.travel;
		parallaxItem.constrainedBackgroundWidth = Math.round(parallaxItem.originalBackgroundWidth * (parallaxItem.constrainedBackgroundHeight / parallaxItem.originalBackgroundHeight));
	}

	return parallaxItem;
}

function resizeParallaxItem(parallaxItem) {
	var height = parallaxItem.constrainedBackgroundHeight;
	var width = parallaxItem.constrainedBackgroundWidth;

	var bgContainerHeight = parallaxItem.bgContainerHeight;

	parallaxItem.element.css("background-size", width + "px " + height + "px");
	parallaxItem.element.css("height", bgContainerHeight + "px");
}

function resizeCachedParallaxItems() {
	//console.log("resizeCachedParallaxItems()");

	resizeCachedParallaxItemsQueued = false;

	updateCachedParallaxItems();

	$.each(parallaxItems, function (index, parallaxItem) {
		resizeParallaxItem(parallaxItem);
	});
}

function transformParallaxItem(parallaxItem) {
	var bottomWindow = winScrollTop + winHeight;
	var currentWindow = (winScrollTop + bottomWindow) / 2;
	var height = parallaxItem.element.height();
	var top = parallaxItem.element.offset().top;
	var bottom = top + height;
	var parallaxRatio = parallaxItem.parallaxRatio;

	//Check to see if we're in the visible area
	if (bottomWindow > top && winScrollTop < bottom) {
		var containerOffset = parallaxItem.element.parent().offset().top;

		//Get the container height
		var containerHeight = parallaxItem.element.parent().height();

		//Determine where the container is in the visible viewport
		var containerRelativeOffset = winScrollTop - containerOffset;
		//console.log("Container visible scroll offset: " + containerRelativeOffset);

		parallaxItem.element.css("transform", "translate3d(0px, " + (containerRelativeOffset * parallaxRatio) + "px, 0px)");
	}
}

function transformCachedParallaxItems() {
	//console.log("transformCachedParallaxItems()");

	transformCachedParallaxItemsQueued = false;

	$.each(parallaxItems, function (index, parallaxItem) {
		transformParallaxItem(parallaxItem);
	});
}

//Noesis animated service boxes
function animateClass(el, delay) {
	el.addClass('hovered').delay(delay).queue(function (next) {
		$(this).removeClass('hovered');
		next();
	});
}

//Google Maps V3 API Async
function initialize() {
	//Set the custom map style
	var mapStyles = [{ "featureType": "administrative", "elementType": "all", "stylers": [{ "visibility": "on" }, { "saturation": -100 }, { "lightness": 20 }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "visibility": "on" }, { "saturation": -100 }, { "lightness": 40 }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "visibility": "on" }, { "saturation": -10 }, { "lightness": 30 }] }, { "featureType": "landscape.man_made", "elementType": "all", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }, { "lightness": 10 }] }, { "featureType": "landscape.natural", "elementType": "all", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }, { "lightness": 60 }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }, { "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }, { "saturation": -100 }, { "lightness": 60 }] }];
	//Noesis: Lat: 45.039657, Lng: -93.226924
	var noesisLatLng = new google.maps.LatLng(45.039657, -93.226924);

	var mapOptions = {
		zoom: 12,
		center: noesisLatLng,
		styles: mapStyles,
		scrollwheel: false,
		draggable: false
	};

	var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

	var contentString = '<div id="map-marker-content"><img src="images/Noesis_Logo_Wide_52.png" title="Noesis Labs" alt="Noesis Labs"></div>';

	var infowindow = new google.maps.InfoWindow({
		content: contentString
	});

	var marker = new google.maps.Marker({
		position: noesisLatLng,
		title: "Noesis Labs, LLC"
	});

	//Open the info window by default
	infowindow.open(map, marker);

	google.maps.event.addListener(marker, 'click', function () {
		infowindow.open(map, marker);
	});

	marker.setMap(map);
}

/* Form Handler */
$("#contact").submit(function (event) {
	event.preventDefault();

	$.ajax({
		type: 'POST',
		url: 'contact/contact.php',
		data: $("#contactForm").serialize(),
		success: function (data) {
			console.log(data);
			if (data == "Success") {
				$("#contactSubmit").fadeOut(300, function () {
					$("#contactForm")[0].reset();
					$("#contactSubmitConfirmation").fadeIn(300);
				});
			}
		}
	});
});

function loadGoogleMapsScript() {
	var script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCcR1T0KBxNIpLPdwYUAuJaMmuEgkgj0NY&v=3.exp&' +
	'callback=initialize';
	document.body.appendChild(script);
}