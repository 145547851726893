$(document).ready(function () {
	GitHubActivity.feed({
		username: "NoesisLabs",
		selector: "#github-feed",
		limit: 10,
		templates: {
			Stream: '<div class="flexslider" id="github-slider">\
						<ul class="slides styled-list">{{{text}}}</ul>\
					</div>',
			Activity: '<li class="github-slide testimonial-slide">\
							<div class="github-feed-item"><div class="gha-activity-icon"><span class="octicon octicon-{{icon}}"></span></div>\
								<div class="gha-message"><div class="gha-time">{{{timeString}}}</div>{{{userLink}}} {{{message}}}</div>\
								<div class="gha-clear"></div>\
							</div>\
						</li>',
			SingleLineActivity: '<li class="github-slide testimonial-slide">\
							<div class="github-feed-item"><div class="gha-activity-icon"><span class="octicon octicon-{{icon}}"></span></div>\
								<div class="gha-message"><div class="gha-time">{{{timeString}}}</div>{{{userLink}}} {{{message}}}</div>\
								<div class="gha-clear"></div>\
							</div>\
						</li>',
			UserHeader: '<!-- header -->',
			NoActivity: '<div class="gha-info">This user does not have any public activity yet.</div>',
			NotFound: '<div class="gha-info">User {{username}} wasn\'t found.</div>',
			CommitCommentEvent: 'commented on commit {{{commentLink}}}<br>{{{userGravatar}}}<small>{{comment}}</small>',
			CreateEvent: 'created {{payload.ref_type}} {{{branchLink}}}{{{repoLink}}}',
			DeleteEvent: 'deleted {{payload.ref_type}} {{payload.ref}} at {{{repoLink}}}',
			FollowEvent: 'started following {{{targetLink}}}',
			ForkEvent: 'forked {{{repoLink}}} to {{{forkLink}}}',
			GistEvent: '{{actionType}} {{{gistLink}}}',
			GollumEvent: '{{actionType}} the {{{repoLink}}} wiki<br>{{{userGravatar}}}<small>{{{message}}}</small>',
			IssueCommentEvent: 'commented on {{issueType}} {{{issueLink}}}<br>{{{userGravatar}}}<small>{{comment}}</small>',
			IssuesEvent: '{{payload.action}} issue {{{issueLink}}}<br>{{{userGravatar}}}<small>{{payload.issue.title}}</small>',
			MemberEvent: 'added {{{memberLink}}} to {{{repoLink}}}',
			PublicEvent: 'open sourced {{{repoLink}}}',
			PullRequestEvent: '{{payload.action}} pull request {{{pullRequestLink}}}<br>{{{userGravatar}}}<small>{{payload.pull_request.title}}</small>{{{mergeMessage}}}',
			PullRequestReviewCommentEvent: 'commented on pull request {{{pullRequestLink}}}<br>{{{userGravatar}}}<small>{{comment}}</small>',
			PushEvent: 'pushed to {{{branchLink}}}{{{repoLink}}}<br>\
					<ul class="gha-commits">{{#payload.commits}}<li><small>{{{committerGravatar}}} {{{shaLink}}} {{message}}</small></li>{{/payload.commits}}</ul>\
					<small class="gha-message-commits">{{{commitsMessage}}}</small>',
			ReleaseEvent: 'released {{{tagLink}}} at {{{repoLink}}}<br>{{{userGravatar}}}<small><span class="octicon octicon-cloud-download"></span>  {{{zipLink}}}</small>',
			WatchEvent: 'starred {{{repoLink}}}'
		}
	});

	//Instantiate a new flexslider once the dynamic content has been loaded
	$('#github-slider').flexslider({
		animation: "fade",
		slideDirection: "horizontal",
		slideshow: true,
		slideshowSpeed: 7000,
		animationDuration: 500,
		directionNav: true,
		controlNav: true
	});
});